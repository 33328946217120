/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Potvrdit účast"}>
        <SiteHeader />

        <Column className="pb--80 pt--80" name={"uvod-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--60" anim={"2"} animS={"5"} style={{"maxWidth":1270}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"Wenn Sie kommen möchten, schreiben Sie uns paar Informationen über sich."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":916}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"8qrbnox2vog"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--30" style={{"maxWidth":1100}} columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 fs--20 w--300 lh--2" style={{"maxWidth":1686}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Wollen Sie alkoholisches oder akoholfreies getränk trinken?","type":"text","placeholder":"Wollen Sie alkoholisches oder akoholfreies getränk trinken?"},{"name":"Kommen Sie auf die After-Party?","type":"text","placeholder":"Ja/Nein"},{"name":"Versenden","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-101ukwm --full pb--60 pt--60" name={"paticka"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/102/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/102/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/102/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/102/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/102/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/102/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/102/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/102/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim6 --anim-s5 --center el--1 flex--center" anim={"6"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" content={"<span style=\"color: white;\">Wir freuen uns auf Ihren Besuch!</span>"}>
              </Title>

              <Button className="btn-box" href={"/de/"} content={"Zurück zur Hauptseite"}>
              </Button>

              <Text className="text-box" content={"<span style=\"color: white;\"><a href=\"https://saywebpage.com\">Webproduktion stránek</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}